/* import __COLOCATED_TEMPLATE__ from './add-source-modal.hbs'; */
/* RESPONSIBLE TEAM: team-knowledge-interop */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/no-default-task-ember-concurrency */
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';
import type ContentImportService from 'embercom/services/content-import-service';
import { task } from 'ember-concurrency-decorators';
import { type TaskGenerator } from 'ember-concurrency';
import { taskFor } from 'ember-concurrency-ts';
import { TrackedArray, TrackedObject } from 'tracked-built-ins';
import { post } from 'embercom/lib/ajax';
import isValidUrl, { isValidUrlGlob, urlDomainsMatch } from 'embercom/lib/url-validator';
import { EntityType } from 'embercom/models/data/entity-types';
import { TriggerSources } from 'embercom/objects/content-service/content-imports/constants/trigger-sources';
import { action } from '@ember/object';
import type GuideLibraryService from 'embercom/services/guide-library-service';
import type IntlService from 'embercom/services/intl';
import { isValidCssSelector } from 'embercom/lib/knowledge-hub/constants';

interface Args {
  modal: any;
  onModalClose: () => void;
  onNavigationBack?: () => void;
  showBackButton?: boolean;
}

type ExcludedUrl = {
  glob: string;
  isValid: boolean;
};

export default class AddSourceModal extends Component<Args> {
  @service declare contentImportService: ContentImportService;
  @service declare appService: any;
  @service declare notificationsService: any;
  @service declare intercomEventService: any;
  @service declare guideLibraryService: GuideLibraryService;
  @service declare intl: IntlService;

  @tracked sourceUrl = '';
  @tracked additionalUrls = '';
  @tracked submissionErrorTranslationKey: string | undefined = undefined;
  @tracked openSectionId = '';
  @tracked excludedUrls = new TrackedArray<ExcludedUrl>([]);
  @tracked removeCssSelectors = '';
  @tracked clickCssSelector = '';
  @tracked useSitemap = false;
  @tracked waitForSelector = '';

  get isUrlInvalid(): boolean {
    return !isValidUrl(this.sourceUrl);
  }

  get isAddingNewSource(): boolean {
    return taskFor(this.addNewSource).isRunning;
  }

  get isAdditionalUrlsInvalid(): boolean {
    if (this.sourceUrl === '') {
      return true;
    }

    if (this.additionalUrls !== '') {
      for (let additionalUrl of this.additionalUrls.split(',')) {
        additionalUrl = additionalUrl.trim();
        if (additionalUrl !== '') {
          // Ignore empty strings caused by trailing or stray commas
          if (!isValidUrl(additionalUrl) || !urlDomainsMatch(this.sourceUrl, additionalUrl)) {
            return true;
          }
        }
      }
    }

    return false;
  }

  get additionalUrlsArray(): string[] {
    return this.additionalUrls
      .split(',')
      .map((url) => url.trim())
      .filter((url) => url !== '')
      .uniq();
  }

  get removeCssSelectorsArray(): string[] {
    return this.removeCssSelectors
      .split(',')
      .map((selector) => selector.trim())
      .filter((selector) => selector !== '')
      .uniq();
  }

  get excludedUrlGlobs(): string[] {
    return this.excludedUrls
      .map(({ glob }) => glob)
      .filter((glob) => glob !== '')
      .uniq();
  }

  get isAnyUrlInvalid(): boolean {
    return this.excludedUrls.some(({ isValid }) => !isValid);
  }

  get isRemoveCssSelectorInvalid(): boolean {
    return this.removeCssSelectors !== '' && !isValidCssSelector(this.removeCssSelectors);
  }

  get isClickCssSelectorInvalid(): boolean {
    return this.clickCssSelector !== '' && !isValidCssSelector(this.clickCssSelector);
  }

  get isSelectorInvalid(): boolean {
    return this.waitForSelector !== '' && !isValidCssSelector(this.waitForSelector);
  }

  get formIsInvalid(): boolean {
    return (
      this.isUrlInvalid ||
      this.isAdditionalUrlsInvalid ||
      this.isAnyUrlInvalid ||
      this.isRemoveCssSelectorInvalid
    );
  }

  get learnMoreUrl() {
    return 'https://www.intercom.com/help/en/articles/9357945-syncing-and-managing-public-urls';
  }

  @task
  *addNewSource(): TaskGenerator<void> {
    this.trackAnalyticsEvent('clicked', 'import_source_button');

    if (!this.isUrlInvalid && !this.isAdditionalUrlsInvalid) {
      let params = {
        app_id: this.appService.app.id,
        url: this.sourceUrl,
        additional_urls: this.additionalUrlsArray,
        trigger_source: TriggerSources.AdminInitiated,
        content_type: EntityType.ExternalContent,
        admin_id: this.appService.app.currentAdmin.id,
        exclude_url_globs: this.excludedUrlGlobs,
        remove_element_css_selectors: this.removeCssSelectorsArray,
        click_element_css_selector: this.clickCssSelector,
        use_sitemap: this.useSitemap,
        wait_for_selector: this.waitForSelector,
      };

      try {
        this.submissionErrorTranslationKey = undefined;
        yield post(`/ember/content_service/content_import_runs`, params);
        this.notificationsService.notifyConfirmation(
          this.intl.t('knowledge-hub.sync-modal.source.sync-started'),
        );
        this.contentImportService.subscribeToContentImportRunStatusUpdates();
        yield this.contentImportService.fetchContentImportSources();
        this.sourceUrl = '';
        this.additionalUrls = '';
        this.removeCssSelectors = '';
        this.clickCssSelector = '';
        this.waitForSelector = '';
        this.additionalUrlsArray.clear();
        this.excludedUrls = new TrackedArray<ExcludedUrl>([]);
        this.args.onModalClose();
        this.useSitemap = false;
        if (this.guideLibraryService.canUseGuideLibraryService) {
          this.guideLibraryService.subscribeToContentImportUpdates();
        }
        let element = window.document.querySelector(
          `[data-knowledge-hub-overview-section="Websites"]`,
        );
        if (element) {
          element.scrollIntoView({ behavior: 'smooth', block: 'center' });
        }
      } catch (e) {
        this.submissionErrorTranslationKey =
          this.contentImportService.submissionErrorTranslationKey(e);
      }
    }
  }

  @action onOpenSectionChange(sectionId: string) {
    this.openSectionId = sectionId;
  }

  @action trackInputAnalytics() {
    this.trackAnalyticsEvent('inputed', 'url_source_input');
  }

  @action trackAdditionalUrlsAnalytics() {
    this.trackAnalyticsEvent('inputed', 'additional_urls_input');
  }

  @action trackRemoveCssSelectorAnalytics() {
    this.trackAnalyticsEvent('inputed', 'remove_css_selector_input');
  }

  @action trackClickCssSelectorAnalytics() {
    this.trackAnalyticsEvent('inputed', 'click_css_selector_input');
  }

  @action trackWaitForSelectorAnalytics() {
    this.trackAnalyticsEvent('inputed', 'wait_for_selector_input');
  }

  @action
  addExcludedUrl() {
    this.excludedUrls.push(new TrackedObject({ glob: '', isValid: false }));
    this.trackAnalyticsEvent('clicked', 'add_url_glob_button');
  }

  @action
  updateExcludedUrl(index: number, event: InputEvent) {
    let glob = (event.target as HTMLInputElement).value;
    this.excludedUrls[index].glob = glob;
    this.excludedUrls[index].isValid = Boolean(isValidUrlGlob(glob));
    this.trackAnalyticsEvent('inputed', 'url_glob_input');
  }

  @action
  deleteExcludedUrl(index: number) {
    this.excludedUrls.splice(index, 1);
    this.trackAnalyticsEvent('clicked', 'delete_url_glob_button');
  }

  @action
  toggleSitemap() {
    this.useSitemap = !this.useSitemap;
    this.trackAnalyticsEvent('clicked', 'use_sitemap_switch');
  }

  private trackAnalyticsEvent(action: string, object: string): void {
    this.intercomEventService.trackAnalyticsEvent({
      action,
      object,
      context: 'side_drawer',
      place: 'external_content',
      section: 'operator',
    });
  }
}

declare module '@glint/environment-ember-loose/registry' {
  export default interface Registry {
    'ContentService::AiContentLibrary::Sources::AddSourceModal': typeof AddSourceModal;
  }
}
