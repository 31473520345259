/* import __COLOCATED_TEMPLATE__ from './node.hbs'; */
/* RESPONSIBLE TEAM: team-workflows */
/* === ⚠️ THIS FILE CURRENTLY USES DEPRECATED PATTERNS ⚠️ === */
/* === 🔗 For more information visit https://go.inter.com/ember-best-practices 🔗 */
/* === 🚀 Please consider refactoring & removing some of the comments below when working on this file 🚀 */
/* eslint-disable @intercom/intercom/require-snake-case-analytics */
import { action } from '@ember/object';
import { inject as service } from '@ember/service';
import Component from '@glimmer/component';
import { tracked } from '@glimmer/tracking';

export default class Node extends Component {
  @service intercomEventService;
  @service intl;
  @service appService;
  @tracked showChildren = this.args.currentJourney.length <= 1;
  @tracked expanded = false;

  get showRightLine() {
    return this.hasChildren && this.showChildren;
  }

  get childFinalIndex() {
    return this.children.length - 1;
  }

  get hasChildren() {
    return this.children.length > 0;
  }

  get isRootNode() {
    return this.args.currentJourney.length <= 1;
  }

  get isZeroViewNode() {
    return this.viewsForCurrentJourney === 0;
  }

  get isFinalPath() {
    return this.children.length === 0;
  }

  get isInstantFinishPath() {
    let path = this.args.currentPath;
    return path.steps.length === 1 && !path.steps.lastObject.canBranch;
  }

  get executionsForCurrentJourney() {
    return this.executionsForJourney(this.args.currentJourney);
  }

  executionsForJourney(journey) {
    return this.args.journeyMap.get(journey.join(','));
  }

  get viewsForCurrentJourney() {
    return this.viewsForJourney(
      this.args.currentJourney,
      this.args.previousPath,
      this.args.currentPath._id,
    );
  }

  viewsForRootNode(journey) {
    let rootNode = this.args.journeyMap.get(''); // The root node tracks the total views before any paths are taken.

    if (
      rootNode &&
      rootNode[''] &&
      (!this.appService.app.shouldFixPathAnalysisForInboundBots || !this.args.customBot.isInbound)
    ) {
      return rootNode[''];
    }

    let executions = this.executionsForJourney(journey);
    if (!executions) {
      return 0;
    }

    return Math.max(...Object.values(executions)) || 0;
  }

  viewsForNonRootNode(previousJourney, previousPath, currentPathId) {
    let executions = this.executionsForJourney(previousJourney);
    if (!executions) {
      return 0;
    }

    let previousPathBranchingSteps = previousPath.branchingSteps;
    let controlsPerBranchingStep = previousPathBranchingSteps.map((s) =>
      s.branchingControls.filter((b) => b.nextPath._id === currentPathId),
    );
    let controlsLeadingToCurrentPath = controlsPerBranchingStep.flat();

    let views = 0;

    controlsLeadingToCurrentPath.forEach((control) => (views += executions[control.uuid] || 0));
    return views;
  }

  viewsForJourney(journey, previousPath, currentPathId) {
    if (journey.length === 1) {
      return this.viewsForRootNode(journey);
    }

    let previousJourney = journey.slice(0, -1);
    return this.viewsForNonRootNode(previousJourney, previousPath, currentPathId);
  }

  get viewsPercentage() {
    let percentage = (this.viewsForCurrentJourney / this.args.viewsForParentNode) * 100;
    return Math.round(percentage) || 0;
  }

  get completions() {
    if (!this.isFinalPath) {
      throw Error(this.intl.t('operator.custom-bot.path-analysis.error.completion'));
    }

    if (this.isInstantFinishPath) {
      return this.viewsForCurrentJourney;
    }

    let executions = this.executionsForCurrentJourney;
    if (!executions) {
      return 0;
    }

    let stepsWithControls = this.args.currentPath.steps.filter((step) => step.hasControls);
    let lastControl = stepsWithControls.lastObject.controls.firstObject;
    return executions[lastControl.uuid] || 0;
  }

  get dropoff() {
    if (this.isFinalPath) {
      return this.viewsForCurrentJourney - this.completions;
    }

    if (this.isInstantFinishPath) {
      return 0;
    }

    let childViews = this.children.map((child) => {
      let childPathId = child._id;
      return this.viewsForJourney(
        [...this.args.currentJourney, childPathId],
        this.args.currentPath,
        childPathId,
      );
    });
    let sumChildViews = childViews.reduce((a, b) => a + b, 0);

    return this.viewsForCurrentJourney - sumChildViews;
  }

  get handoverStep() {
    return this.args.currentPath.steps.find(
      (step) => step.triggerableWorkflowId,
      this.args.currentPath.steps,
    );
  }

  get displayDropoffNode() {
    return this.dropoff > 0;
  }

  get children() {
    let branchingSteps = this.args.currentPath.branchingSteps;
    let branchesPerStep = branchingSteps.map((s) => s.branchingControls.map((b) => b.nextPath));

    return branchesPerStep.flat().uniq();
  }

  @action toggleChildren() {
    if (this.args.depth === 0 || !this.hasChildren) {
      return;
    }

    this.showChildren = !this.showChildren;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'path-analysis-show-children',
      show_children: this.showChildren,
      path_id: this.args.currentPath._id,
      custom_bot_id: this.args.customBot.id,
      custom_bot_target: this.args.customBot.target,
    });
  }

  @action toggleExpanded() {
    this.expanded = !this.expanded;

    this.intercomEventService.trackAnalyticsEvent({
      action: 'toggled',
      object: 'path-analysis-expand-path',
      expanded: this.expanded,
      path_id: this.args.currentPath._id,
      custom_bot_id: this.args.customBot.id,
      custom_bot_target: this.args.customBot.target,
    });
  }
}
